
import Vue from 'vue'
import moment from 'moment'
import { mapActionsTyped, mapStateTyped } from '../store'

export default Vue.extend({
  name: 'SearchBar',
  inject: ['rewriteProps'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
      fromDate: new Date(Date.now() - 60 * 60 * 1000).toString(),
      toDate: new Date().toString(),
      number: '',
      brand: '',
      brandSearch: '',
      maxDate: Date.now(),
      timezone: '',

      checkMax: (str: string) => str.length < 10 || 'Длина должна быть не больше 8 символов',
      checkLang: (str: string) => !/[А-Яа-я]/.test(str) || 'Только латинские буквы и символы',
    }
  },
  computed: {
    ...mapStateTyped(['isBrandsLoading', 'brands']),
  },
  mounted() {
    if (!this.brands.length && !this.isBrandsLoading) {
      this.loadBrands()
    }
    // @ts-ignore
    this.timezone = this.rewriteProps.tz
  },
  methods: {
    ...mapActionsTyped(['loadBrands']),
    ...mapStateTyped(['isSignsListLoading']),
    clear() {
      this.fromDate = ''
      this.toDate = ''

      this.number = ''
      this.brand = ''
      this.brandSearch = ''

      this.$emit('clear')
    },
    clearBrandSearch() {
      this.brandSearch = ''
    },
    search() {
      moment.suppressDeprecationWarnings = true // чтобы убрать warning в консоли

      const from = moment(this.fromDate).toDate()
      const to = moment(this.toDate).toDate()

      this.$emit('search', {
        from: from.getTime() ? from : undefined,
        to: to.getTime() ? to : undefined,
        number: this.number ? this.number : undefined,
        brand: this.brand ? this.brand : undefined,
      })
    },
    brandsBlur() {
      if (
        this.brandSearch &&
        !this.brands.some((brand) => brand.name.toLowerCase() === this.brandSearch.toLowerCase())
      ) {
        this.brandSearch = ''
      }
    },
    isSearchDisabled() {
      return !this.fromDate || !this.toDate
    },
  },
})
